import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';

const media = {
  secTitle: 'Richmond, VA',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/vwoYJrH7LoI',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Virginia is a state that has informed our nation’s history significantly
      since our founding. This year, Virginians remember the 400th anniversary
      of the arrival of the first enslaved Africans to their state. The city of
      Richmond, as the State capital and the former capital of the Confederacy,
      has held discussions and events to mark this milestone. Richmond is a
      majority-people-of-color city, ahead of the national curve on demographic
      change with 48 percent black and 12 percent identifying as other than
      white. And, its recent population growth has been even between whites and
      people of color. However, unemployment is more than three times higher
      among black workers (14 percent) than their white counterparts (4
      percent). The wage gap is also a major barrier for Richmond’s economic
      vitality with white workers paid approximately $20,000 more per year than
      black and Latino workers. Richmond’s metro economy could be $14 billion
      stronger without racial income gaps. More than 1 in 6 young people of
      color are neither working nor in school. And, more than 3 out of 4 jobs in
      Richmond are held by workers living outside the city limits.
    </p>
    <p>
      In Richmond we conducted individual interviews and led several small group
      discussions, both with Richmond Hill and Initiatives of Change. At the
      Virginia Museum of History and Culture, Richmond Mayor Levar Stoney and
      Mayor Landrieu discussed how local leaders can tackle critical social
      issues including racism and racial disparities, the history and symbolism
      of monuments, how to chart a path toward dismantling inequities, and how
      their two cities can learn from each other. We also conducted focus groups
      with non-college-educated black and white residents and college-educated
      black residents, all under the age of 35. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
